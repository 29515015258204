<template>
  <div class="welcomeHome">
    <MDBContainer>
      <MDBRow>
        <MDBCol>
          <div class="p-5 text-center bg-light">
            <h1 class="mb-3">Requilend</h1>
            <h4 class="mb-3">Financial services tailored to meet the needs of your growing business!</h4>
            <a class="btn btn-primary" href="" role="button">See how we can help</a>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  </div>
</template>

<script>
import { MDBContainer, MDBRow, MDBCol } from "mdb-vue-ui-kit";

export default {
  name: "WelcomeHome",
  components: {
    MDBContainer,
    MDBRow,
    MDBCol,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
