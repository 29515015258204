<template>
  <MDBNavbar expand="lg" light bg="light" container class="mb-5">
    <MDBNavbarBrand href="#">Requilend</MDBNavbarBrand>
    <MDBNavbarToggler
      @click="collapse1 = !collapse1"
      target="#navbarSupportedContent"
    ></MDBNavbarToggler>
    <MDBCollapse v-model="collapse1" id="navbarSupportedContent">
      <MDBNavbarNav class="mb-2 mb-lg-0">
        <!-- <MDBNavbarItem to="#" active> Home </MDBNavbarItem> -->
        <!-- <MDBNavbarItem to="/about"> About </MDBNavbarItem> -->
        <MDBNavbarItem>
          <!-- Navbar dropdown -->
          <MDBDropdown class="nav-item" v-model="lendingDropdown">
            <MDBDropdownToggle
              tag="a"
              class="nav-link"
              @click="lendingDropdown = !lendingDropdown"
              >Lending</MDBDropdownToggle
            >
            <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
              <MDBDropdownItem href="#">Merchant Cash Advance</MDBDropdownItem>
              <MDBDropdownItem href="#">Lines of Credit</MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavbarItem>
        <MDBNavbarItem>
          <MDBDropdown class="nav-item" v-model="merchantServicesDropdown">
            <MDBDropdownToggle
              tag="a"
              class="nav-link"
              @click="merchantServicesDropdown = !merchantServicesDropdown"
              >Merchant Services</MDBDropdownToggle
            >
            <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
              <MDBDropdownItem href="#">Get A Merchant</MDBDropdownItem>
              <MDBDropdownItem href="#">Get A Better Rate</MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavbarItem>
        <MDBNavbarItem to="/about"> Our Story </MDBNavbarItem>
      </MDBNavbarNav>
      <MDBBtn>Login</MDBBtn>
    </MDBCollapse>
  </MDBNavbar>
  <!-- PUT NAVBAR HERE-->
  <MDBContainer>
    <MDBRow>
      <MDBCol>
        <router-view class="m-5" style="height: 50vh;"></router-view>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
   <MDBFooter :text="['center', 'lg-start']" class='fixed-bottom'>
    <!-- Grid container -->
    <MDBContainer class="p-4">
      <!--Grid row-->
      <MDBRow>
        <!--Grid column-->
        <MDBCol lg="6" md="12" class="mb-4 mb-md-0">
          <div class="m-5">
          <h5 class="text-uppercase">WORK IN PROGRESS</h5>

          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
            Iste atque ea quis molestias. Fugiat pariatur maxime quis
            culpa corporis vitae repudiandae aliquam voluptatem veniam,
            est atque cumque eum delectus sint!
          </p>
          </div>
        </MDBCol>
        
      </MDBRow>
      <!--Grid row-->
    </MDBContainer>
    <!-- Grid container -->

    <!-- Copyright -->
    <div
      class="text-center p-3"
      style="background-color: rgba(0, 0, 0, 0.2);"
    >
      © 2021 Copyright Requilend, Inc.
    </div>
    <!-- Copyright -->
  </MDBFooter>
</template>

<script>
import {
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBtn,
  MDBFooter,
} from "mdb-vue-ui-kit";
import { ref } from "vue";

export default {
  name: "App",
  components: {
    MDBBtn,
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBCollapse,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBFooter,
  },
  setup() {
    const collapse1 = ref(false);
    const lendingDropdown = ref(false);
    const merchantServicesDropdown = ref(false);

    return {
      collapse1,
      lendingDropdown,
      merchantServicesDropdown,
    };
  },
};
</script>

<style lang="scss">
@import "~@/../mdb/scss/index.free.scss";

#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}
</style>
