<template>
    <WelcomeHome/>
</template>

<script>
// @ is an alias to /src
import WelcomeHome from '@/components/WelcomeHome.vue'

export default {
  name: 'Home',
  components: {
    WelcomeHome
  }
}
</script>
